button:focus {
  outline: 0;
}

// PRIMARY
.btn-primary {
    background-color: $color-3 !important;
    border: 1px solid $color-3;
    color: $color-1 !important;
}

.btn-primary:focus {
    background-color: $color-2 !important;
    border: 1px solid $color-2;
    color: $color-1 !important;
}

.btn-primary:hover {
    background-color: $color-6 !important;
    border: 1px solid $color-6;
    color: $color-1 !important;
}

.btn-outline-primary {
    background-color: $color-1 !important;
    border: 1px solid $color-2;
    color: $color-2 !important;
}

.btn-outline-primary:focus {
    background-color: $color-5 !important;
    border: 1px solid $color-2;
    color: $color-2 !important;
}

.btn-outline-primary:hover {
    background-color: $color-6 !important;
    border: 1px solid $color-2;
    color: $color-2 !important;
}

// SECONDARY
.btn-secondary {
    background-color: $color-9 !important;
    border: 1px solid $color-9;
    color: $color-1 !important;
}

.btn-secondary:focus {
    background-color: $color-8 !important;
    border: 1px solid $color-8;
    color: $color-1 !important;
}

.btn-secondary:hover {
    background-color: $color-12 !important;
    border: 1px solid $color-12;
    color: $color-1 !important;
}

