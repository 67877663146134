/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@mdi/font/css/materialdesignicons.css';
@import '~ngx-toastr/toastr';
@import '~@ng-select/ng-select/themes/default.theme.css';

@import 'partials';

// Pagina global
.page {
  height: 100%;
  min-height: 100vh;
  padding-top: 1rem;
  padding-bottom: 3.5rem;
  margin-top: 3.5rem;

  &-actions {
    padding-top: 0rem;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    h1 {
      margin-top: auto;
      margin-bottom: auto;
      color: $color-8!important;
      font-size: 1rem!important;
      font-weight: 600;
    }
  }
  &-buttons {
    a,
    button {
      margin-left: 0.75rem;
    }
  }
}

// Full layout
.full-layout {
  // background-image: url('/assets/images/wave.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  // Debe ser del tamanio del SIDEBAR
  margin-left: 177px; 

  .tabs {
    li.nav-item {
      a.nav-link {
        color: #ffff !important;
      }
    }
  }

  .bold {
    font-weight: bold !important;
  }

  [role='button'],
  button,
  input[type='button'],
  input[type='checkbox'],
  input[type='file'],
  input[type='image'],
  input[type='radio'],
  input[type='reset'],
  input[type='submit'],
  label,
  .pointer {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  .form-actions {
    .btn {
      margin-left: 0.5rem;
    }
  }
}

// Single layout
.simple-layout {
  height: auto;
  min-height: 100vh;
  background: #ffff;
  // background-image: url('../../assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .simple-page {
    .container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%); /* for IE 9 */
      -webkit-transform: translate(-50%, -50%); /* for Safari */
      width: 100%;
      padding: 15px;
      margin: auto;
    }
  }
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #f46a6a !important;
}

.is-select-invalid > div {
  border: 1px solid #f46a6a !important;
}

.invalid-select-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #f46a6a !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #f46a6a !important;
}

html, body { height: 100%; }
body { margin: 0; background-color: $color-13; font-family: Roboto, 'Helvetica Neue', sans-serif; }
h1 {
  color: $color-8 !important;
}


// Side bar

#sidebar-wrapper {
  // height: 700px;
  min-height: 100% !important;
  height: 100%;
  
  position: fixed;
  z-index: 1;

  // margin-left: -15rem;
  // transition: margin 0.25s ease-out;
  background: $color-8 !important;
  
  

  /* .list-group-item {
    background: #2a2a2a;
    color: #f5f5f5;
  }

  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #414141;
    border-color: #414141;
  } */
}

#sidebar-wrapper .sidebar-heading {
  background: #ffff !important;
  height: 3.5rem;
  img {
    max-width: 7.5rem;
    width: 7.5rem;
  }
  padding: 0.3rem 1rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
  // width: 3rem;
}

#sidebar-wrapper .sidebar-icons {
  width: 50px;
}

#page-content-wrapper {
  min-width: 100vw;
}

body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

body #wrapper #side-wrapper {
  width: 25px;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  body.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -15rem;
  }
}


