$color-1: #ffffff;

// Naranjas
$color-2: #ff6b00;
$color-3: #ff8c1c;
$color-4: #ff9b41;
$color-5: #ffd4ac;
$color-6: #ffe2c7;
$color-7: #fff0e2;

// Grises
$color-8: #5b6670;
$color-9: #79838b;
$color-10: #899199;
$color-11: #99a0a6;
$color-12: #cacdd1;
$color-13: #dbdee0;
$color-14: #ecedef;
